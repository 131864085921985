.hoox-allure-quote {
    background-color: var(--hoox-color-sand);
    padding-top: 30px;
    padding-bottom: 25px;
}
.hoox-allure-quote__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 23px;
}

.hoox-allure-quote__text {
    line-height: 24px;
}

@media (min-width: 767px) {
    .hoox-allure-quote__wrapper {
        max-width: 790px;
        margin-inline: auto;
    }
    .hoox-allure-quote {
        padding: 40px 0;
    }
    .hoox-allure-quote__text {
        line-height: 26px;
    }
}

@media (max-width: 768px) {
    .hoox-allure-quote .hoox-allure-quote__text br {
        display: none;
    }
}